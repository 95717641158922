import React from "react"
import { ClearRefinements } from 'react-instantsearch-dom';

import {
    connectCurrentRefinements
  } from 'react-instantsearch-dom';

  const CurrentRefinements = ({ items, refine }) => {

    const filterTotal = [];

    items.forEach(item => {
      if (Array.isArray(item.currentRefinement)){
        item.currentRefinement.forEach(filter => {
          if(filterTotal.includes(filter)) {
          } else {
            filterTotal.push(filter);
          }
        })
      } else {
        filterTotal.push(item.currentRefinement.max);
      }
    })

    //Check if any filters are applied
    return (items.length > 0 ?
      <div className="sl_filter__filters">
        <p className="sl_filter__current">{filterTotal.length} Filters</p>
        <ClearRefinements
            translations={{
                reset: 'Clear all',
            }}
            clearsQuery
        />
      </div>
    // If no filters do nothing
    : null);
  };

const CustomCurrentRefinements = connectCurrentRefinements(CurrentRefinements);
export default CustomCurrentRefinements;