import React from "react"
import { RefinementList, connectRefinementList, NumericMenu } from 'react-instantsearch-dom';
import { orderBy } from 'lodash'
import CustomCurrentRefinements from "../currentrefinements"

const CustomRefinementList = connectRefinementList(
    ({ limit, items, refine }) => {
        const itemsByLabel = orderBy(items, 'label');
        return (
            <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
                {itemsByLabel
                    .filter(item => item.label[0] !== '-')
                    .slice(0, limit / 2 + 1)
                    .map(item => {
                        const isNegativeRefined = item.value.indexOf('-' + item.label) > -1;
                        const valueWithoutItself = item.value.filter(
                            value => value !== item.label && value !== '-' + item.label
                        );
                        let isCheckedCustom = false;
                        if (item.isRefined) {
                            isCheckedCustom = true;
                        }
                        else {
                            if (item.label === 'child' || item.label === 'adolescent') {
                                //console.log('yes');
                                //console.log(item.value);
                                if (items.some(item => item.label === '-adult') || items.some(item => item.label === '-senior')) {
                                    //console.log('yes2');
                                    isCheckedCustom = true;
                                }
                            }
                        }
                        return (
                            <li key={item.label} className="ais-RefinementList-item ais-RefinementList-item--selected">
                                <label className="ais-RefinementList-label">
                                    <input
                                        type="checkbox"
                                        className="ais-RefinementList-checkbox"
                                        checked={isCheckedCustom}
                                        onChange={() => {
                                            // swap adolescent + child ONLY scenario for -adult + -senior
                                            if (item.value.includes('adolescent') && item.value.includes('child') &&
                                                !item.value.includes('adult')) { // && !item.value.includes('senior')
                                                item.value.splice('adolescent');
                                                item.value.splice('child');
                                                item.value.push('-adult');
                                                //item.value.push('-senior');
                                                //console.log('swap');
                                            }
                                            else {
                                                // replace -adult and -senior and restore correct checkbox
                                                if (item.value.includes('-adult')) { // && item.value.includes('-senior')
                                                    item.value.splice('-adult');
                                                    //item.value.splice('-senior');

                                                    //console.log('unswap');

                                                    if (isCheckedCustom) {
                                                        if (item.label == 'child') {
                                                            item.value.push('adolescent');
                                                        }
                                                        else if (item.label == 'adolescent') {
                                                            item.value.push('child');
                                                        }
                                                    }
                                                    else {
                                                        item.value.push('adolescent');
                                                        item.value.push('child');
                                                        item.value.push(item.label);
                                                    }
                                                }
                                            }
                                            //console.log(item.value);
                                            refine(item.value);
                                        }}
                                    />
                                        <span className="ais-RefinementList-labelText">
                                        {
                                            item.label === "child" ? "Children (2-12)" :
                                            item.label === "adolescent" ? "Adolescents (13-17)" :
                                            item.label === "adult" ? "Adults (18-65)" :
                                            item.label === "senior" ? "Seniors (65+)" : ''
                                        }
                                        </span>
                                        <span className="ais-RefinementList-count">{item.count}</span>
                                </label>
                            </li>
                        );
                    })
                }
            </ul>
            </div>
        );
    }
);

const FilterSideBar = () => {
    return (
        <>
            <CustomCurrentRefinements />
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Levels of Care</p>
                <RefinementList
                    attribute="services.levelCare.name"
                    transformItems={items => orderBy(items, "label", "asc")}
                    showMore
                />
            </div>
            {/*<div className="sl_filter__cat">
                <p className="sl_filter__title">Ages Treated</p>
                <RefinementList
                    attribute="services.age.ageGroup"
                    transformItems={items =>
                        orderBy(
                            items.map(item => ({
                                ...item,
                                label: item.label === "child" ? "Children (2-12)" :
                                    item.label === "adolescent" ? "Adolescents (13-17)" :
                                    item.label === "adult" ? "Adults (18-65)" :
                                    item.label === "senior" ? "Seniors (65+)" : ''
                            })), "value", "asc"
                        )
                    }
                />
            </div>*/}
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Ages Treated</p>
                <CustomRefinementList
                    attribute="services.age.ageGroup"
                    operator="or"
                    limit={100} />
            </div>

            {/*<div className="sl_filter__cat">
                <p className="sl_filter__title">Age Limit</p>
                <NumericMenu
                    attribute="services.age.max"
                    items={[
                        { label: 'Up to 18 Only', end: 18 },
                    ]}
                    transform={items => items.filter(item => item.value <= 18)}
                    operator="or"
                />
            </div>*/}
            {/*<div className="sl_filter__cat">
                <p className="sl_filter__title">Maximum Age</p>
                <RefinementList
                    attribute="services.age.max"
                    transformItems={items =>
                        orderBy(
                            items.map(item => ({
                               ...item,
                                value: item.value = "<" + item.value
                            })), items, "value", "asc"
                    )}
                />
            </div>*/}
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Disorders Treated</p>
                <RefinementList
                attribute="services.diagnosis.name" 
                transformItems={items => orderBy(items, "label", "asc")}
                translations={{
                    showMore(expanded) {
                      return expanded ? 'See Less' : 'See All Categories';
                    }
                }}
                />
            </div>
            <div className="sl_filter__cat">
                <p className="sl_filter__title">Specialty Programs</p>
                <RefinementList 
                showMore
                limit={15}
                showMoreLimit={100}
                attribute="specialty" 
                transformItems={items => orderBy(items, "label", "asc")}
                translations={{
                    showMore(expanded) {
                      return expanded ? 'See Less' : 'See All Categories';
                    }
                }}
                />
            </div>
            <div className="sl_filter__callout">
                <p>Visit our website to see a map of all UHS locations including Acute Care hospitals, Freestanding Emergency Departments, Behavioral Health Facilities, Ambulatory Surgery Centers and Physician Networks.</p>
                <a className="sl_button sl_button--border sl_button--external" href="https://www.uhsinc.com/our-communities/" target="_blank" rel="noreferrer">View All Locations</a>
            </div>
        </>
    );    
};
export default FilterSideBar